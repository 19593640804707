import SceneWrapper from './SceneWrapper';
import { isPortrait } from './utils';
import scene_1 from '../assets/mobile/scene_1.webp';
import scene_2 from '../assets/mobile/scene_2.webp';
import scene_3 from '../assets/mobile/scene_3.webp';
import scene_4 from '../assets/mobile/scene_4.webp';
import scene_5 from '../assets/mobile/scene_5.webp';
import scene_6 from '../assets/mobile/scene_6.webp';

customElements.define('scene-wrapper', SceneWrapper);
const sceneWrapper = document.querySelector('scene-wrapper') as SceneWrapper;

const scenes = [ 'scene_1', 'scene_2', 'scene_3', 'scene_4', 'scene_5', 'scene_6', 'thankyoupage', '404' ];
const scenes_backgrounds = {
    scene_1,
    scene_2,
    scene_3,
    scene_4,
    scene_5,
    scene_6
}

console.log(scenes_backgrounds);

let current_scene = scenes.includes(window.location.hash.substring(1)) ? window.location.hash.substring(1) : 'scene_1';

// console.log(window.location.hash.substring(1))

/* window.addEventListener("message", (event) => {
  console.log('eve', event.data);
}) */

const intersectionObserver = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.intersectionRatio > 0.9) {
            window.location.hash =  entry.target.id;
            const el = entry.target! as HTMLElement;
            el.querySelector('.wrapper')!.style!.display = 'block';
            if (!el.classList.contains('loaded')) {
                const image = new Image();
                image.addEventListener('load', () => {
                    console.log('aaa');
                    el.style.backgroundImage = `var(--gradient), url(${scenes_backgrounds[entry.target.id]})`;
                    el.classList.add('loaded');
                })
                image.src = scenes_backgrounds[entry.target.id];
            }
           
        }
    });
}, {
    threshold: [0.9],
    root: document.querySelector('#scene_container')
});

const scenesElements = document.querySelectorAll('#scene_1, #scene_2, #scene_3, #scene_4, #scene_5, #scene_6') as NodeListOf<HTMLElement>;

window.addEventListener("message", (event) => {
    if (event.data === 'loadscene:scene_1') {
        import('./scene_1').then((scene) => {
            sceneWrapper?.loadScene(scene.default);
        });
    }
});

window.addEventListener("message", (event) => {
    if (event.data === 'loadscene:scene_2') {
        import('./scene_2').then((scene) => {
            sceneWrapper?.loadScene(scene.default);
        });
    }
});

window.addEventListener("message", (event) => {
    if (event.data === 'loadscene:scene_3') {
        import('./scene_3').then((scene) => {
            sceneWrapper?.loadScene(scene.default);
        });
    }
});
            
window.addEventListener("message", (event) => {
    if (event.data === 'loadscene:scene_4') {
        import('./scene_4').then((scene) => {
            sceneWrapper?.loadScene(scene.default);
        });
    }
});

window.addEventListener("message", (event) => {
    if (event.data === 'loadscene:scene_5') {
        import('./scene_5').then((scene) => {
            sceneWrapper?.loadScene(scene.default);
        });
    }
});
            
window.addEventListener("message", (event) => {
    if (event.data === 'loadscene:scene_6') {
        import('./scene_6').then((scene) => {
            sceneWrapper?.loadScene(scene.default);
        });
    }
});
            
window.addEventListener("message", (event) => {
    if (event.data === 'loadscene:thankyoupage') {
        import('./thankyoupage').then((scene) => {
            sceneWrapper?.loadScene(scene.default);
        });
    }
});

window.addEventListener("message", (event) => {
    if (event.data === 'loadscene:404') {
        import('./404').then((scene) => {
            sceneWrapper?.loadScene(scene.default);
        });
    }
});

const onSubmitMobile = () => {
    scenesElements.forEach((scene) => { scene.style.display = 'none'; });
    document.getElementById("thankyoupage")!.style.display = 'block';
}

const responsivity = () => {
    if (isPortrait()) {
        current_scene = scenes.includes(window.location.hash.substring(1)) ? window.location.hash.substring(1) : 'scene_1';
        document.getElementById("scene_container")!.style.scrollBehavior = 'auto';
        document.getElementById("scene_container")!.scrollTop = document.getElementById(current_scene)!.offsetTop;
        document.getElementById("scene_container")!.style.scrollBehavior = 'smooth';
        console.log(current_scene);
        scenesElements.forEach((scene) => {
            intersectionObserver.observe(scene);
        });
        scenesElements.forEach((scene) => {
            scene.style.display = 'block';
            if (current_scene !== scene.id) {
                scene!.querySelector('.wrapper')!.style!.display = 'none';
            }
        });
        document.getElementById("fire_form")!.addEventListener("submit", onSubmitMobile);
    } else {
        scenesElements.forEach((scene) => {
            intersectionObserver.unobserve(scene);
        });
        scenesElements.forEach((scene) => {
            current_scene = scenes.includes(window.location.hash.substring(1)) ? window.location.hash.substring(1) : 'scene_1';
            if (scene.id !== current_scene) {
                scene.style.display = 'none';
            }
            window.postMessage('loadscene:' + current_scene);
        });
        document.getElementById("fire_form")!.removeEventListener("submit", onSubmitMobile);
    }
}

responsivity();

window.addEventListener('resize', responsivity);


