
export const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const isMobile = () => {
    const ua = navigator.userAgent;
    return /Android|Mobi/i.test(ua);
    }

export const isPortrait = () => {
    return window.innerHeight > window.innerWidth;    
}