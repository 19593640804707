import { Scene } from './Scene';
import { isPortrait, wait } from './utils';

class SceneWrapper extends HTMLElement {

    camera: HTMLDivElement;
    styleTag: HTMLStyleElement;
    cameraScale = 1;
    currentSceneId: string;
    baseStyle = `
    #wrapper {
        perspective: 50vw;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100vw;
        height: 100%;
        overflow: hidden;

    }
    
    #camera {
        transform-style: preserve-3d;
        transform-origin: 50% 50%;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    
    #camera>* {
        position: absolute;
        top: 0;
        left: 0;
        /* object-fit: cover; */
    }

@media screen and (orientation: portrait){
 #wrapper {
        perspective: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        width: 100vw;
        height: 100vh;
        overflow: hidden;

    }
    
    #camera {
        transform-style: flat;
        transform-origin: top left;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
    #camera>* {
        position: absolute;
        top: 0;
        left: 0;
        transform-origin: center;
    }
  }
    
    `

    parallaxActive = false;

    constructor() {
        super();

        this.attachShadow({ mode: 'open' });

        this.styleTag = document.createElement('style');
        this.styleTag.textContent = this.baseStyle;

        const wrapper = document.createElement('div');
        wrapper.setAttribute('id', 'wrapper');

        this.camera = document.createElement('div');
        this.camera.setAttribute('id', 'camera');

        this.shadowRoot?.append(this.styleTag, wrapper)
        wrapper.append(this.camera)

        this._cssParallax = this.cssParallax.bind(this)

        this.windowRatio();
        window.addEventListener("resize", () => { this.windowRatio() })
        
    }

    stopParallax() {
        window.removeEventListener('mousemove', this._cssParallax);
        this.parallaxActive = false;
    }

    startParallax() {
        if (this.parallaxActive) return;
        window.addEventListener('mousemove', this._cssParallax);
        this.parallaxActive = true;
    }

    updateStyle(style: string) {
        this.styleTag.textContent = this.baseStyle + style;
    }

    windowRatio() {
        let ratio = window.innerHeight / window.innerWidth;
        if (ratio > 0.5625) {

        } else {

        }
    }

    cssParallax(event) {

        const radiusVal = 20;

        let x = window.innerWidth;
        let y = window.innerHeight;

        let cx = Math.ceil(x / 2.0);
        let cy = Math.ceil(y / 2.0);
        let dx = event.pageX - cx;
        let dy = event.pageY - cy;

        let tiltx = (dy / cy) * 0.1;
        let tilty = - (dx / cx) * 0.3;

        let radius = Math.sqrt(Math.pow(tiltx, 2) + Math.pow(tilty, 2));
        let degree = (radius * radiusVal);
        // console.log('rotate3d(' + tiltx + ', ' + tilty + ', 0, ' + degree + 'deg)')
        // console.log(`rotate3d(${tiltx}, ${tilty}, 0, ${degree}deg) scale(${this.cameraScale})`)
        // el.style.transform = 'rotate3d(' + tiltx + ', ' + tilty + ', 0, ' + degree + 'deg)';

        this.camera.style.transform = `rotate3d(${tiltx}, ${tilty}, 0, ${degree}deg) scale(${this.cameraScale})`;

    }

    cameraGoTo() {
        // this.camera.style.transform = `rotate3d(${tiltx}, ${tilty}, 0, ${degree}deg) scale(${this.cameraScale})`
    };

    async loadScene(scene: Scene) {
        if (this.currentSceneId === scene.id) return;
        this.unLoadScene();
        this.currentSceneId = scene.id;
        if (isPortrait()) {
            scene.fallback?.();
        } else {
            const promises: Promise<any>[] = []
            for (let id in scene.assets) {
                let svg = document.createElement("object")
                svg.id = id
                svg.type = "image/svg+xml"
                svg.data = scene.assets[id]
                this.camera.append(svg);
                promises.push(new Promise((resolve) => svg.onload = resolve))
            }
            this.updateStyle(scene.style);
            await promises;
            scene.onMounted?.(this);
            await wait(300);
            document.getElementById("loader-screen")!.style.opacity = "0";
        }
    }

    unLoadScene() {
        this.camera.innerHTML = '';
    }


}

export default SceneWrapper;